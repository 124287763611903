import styles from './SponsorCardsContainer.module.scss';
import SponsorCard from './SponsorCard';
import { useRef, useEffect, useState } from 'react';

export default function SponsorCardsContainer({ sponsors }) {
  const containerRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState('right');

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const scrollSpeed = 0.7;
    let scrollInterval;

    const startScrolling = () => {
      scrollInterval = setInterval(() => {
        if (scrollDirection === 'right') {
          container.scrollLeft += scrollSpeed;
          if (
            container.scrollLeft + container.clientWidth >=
            container.scrollWidth
          ) {
            setScrollDirection('left');
          }
        } else {
          container.scrollLeft -= scrollSpeed;
          if (container.scrollLeft <= 0) {
            setScrollDirection('right');
          }
        }
      }, 100);
    };

    startScrolling();

    return () => clearInterval(scrollInterval);
  }, [scrollDirection]);
  return (
    <div className={styles.sponsorsCardsContainer}>
      <h3>Our Sponsors</h3>
      <div className={styles.sponsorsCards} ref={containerRef}>
        {sponsors.map((sponsor, index) => (
          <SponsorCard
            key={`sponsor-${index}`}
            img={sponsor.logo}
            title={sponsor.name}
            link={sponsor.url}
          />
        ))}
      </div>
    </div>
  );
}

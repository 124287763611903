import React, { useState, useRef, useEffect } from 'react';
import styles from './Header.module.scss';
import { Link, useLocation } from 'react-router-dom';
import {
  faTimesCircle,
  faBars,
  faCartShopping,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavItem from '../NavItem/NavItem';
import BrandLogo from '../BrandLogo/BrandLogo';
import useOutsideClick from '../../utils/useOutsideClick';
import AuthNLngContainer from './AuthNLngContainer';
import { useCart } from '../../context/useCart';
import Timer from '../Timer/Timer';

const CartBtn = ({ cartCount }) => (
  <Link to={'/cart'} className={styles.cartBtn}>
    <span className={styles.cartCount}>{cartCount}</span>
    <FontAwesomeIcon icon={faCartShopping} />
  </Link>
);

/**
 * React functional component that renders the header module.
 *
 * @param {Object} props - The properties object containing the following properties:
 *   @property {Function} handleLogout - The function to handle user logout.
 * @return {JSX.Element} The rendered header component.
 */
export default function Header() {
  const modalRef = useRef();
  const togglerRef = useRef();
  const { pathname } = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { cartCount } = useCart();

  // Use the custom hook to handle outside click
  useOutsideClick(modalRef, (event) => {
    if (
      !isCollapsed &&
      togglerRef.current &&
      !togglerRef.current.contains(event.target)
    ) {
      setIsCollapsed(true);
    }
  });

  // Make the header colapse every time the pathname changes
  useEffect(() => {
    setIsCollapsed(true);
  }, [pathname]);

  // Function to change the state of the navigation menu when the hamburger icon is clicked
  const handleChangeCollapsed = (e) => {
    e.stopPropagation();
    setIsCollapsed((prevValue) => !prevValue);
  };
  return (
    <header className={styles.header}>
      <nav className={styles.navbarContainer}>
        <div className={styles.mobileShow}>
          <BrandLogo />
          <div className={styles.mobileShowBtns}>
            <button
              ref={togglerRef}
              onClick={handleChangeCollapsed}
              className={`${styles.navbarToggler} ${
                isCollapsed && styles.collapsed
              }`}
            >
              <FontAwesomeIcon icon={faBars} className={styles.hamburger} />
              <FontAwesomeIcon icon={faTimesCircle} className={styles.close} />
            </button>
            <div className={styles.cartBtnContainer}>
              <CartBtn cartCount={cartCount} />
              <Timer variant="header" />
            </div>
          </div>
        </div>
        <div
          className={`${styles.navLinksContainer} ${
            isCollapsed && styles.collapsed
          }`}
          ref={modalRef}
        >
          <div className={styles.links}>
            <NavItem linkTo="" label="Home" />
            <NavItem linkTo="events" label="Events" />
            <NavItem linkTo="schedule-demo" label="Pricing" />
            <NavItem linkTo="about" label="About Us" />
            <AuthNLngContainer />
            <div className={styles.cartBtnContainer}>
              <CartBtn cartCount={cartCount} />
              <Timer variant="header" />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login2 } from '../../utils/APIUtils';
import FormInput from '../../components/FormInput/FormInput';
import { useAuth } from '../../context/useAuth';
import AuthenticationHead from '../../components/AuthenticationHead/AuthenticationHead';
import AuthenticationFoot from '../../components/AuthenticationFoot/AuthenticationFoot';
import KeepMeSignAndForgot from '../../components/KeepMeSignAndForgot/KeepMeSignAndForgot';
import { verifyemail } from '../../utils/APIUtils';
import ConfirmVerificationCode from '../../components/ConfirmVerificationCode/ConfirmVerificationCode';
import styles from './Login.module.scss';
import { loginValidation } from '../../utils/authValidationUtils';
import CustomButton from '../../components/CustomButton/CustomButton';
import * as storageUtils from '../../utils/LocalStorageUtils';

/**
 * Login function is a React functional component that exports a default function
 * named Login. This component is responsible for handling user login functionality.
 *
 * @return {JSX.Element} Returns a JSX element that renders the login form.
 */
export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  // States for controlled components
  const [email, setEmail] = useState('diegotertomartins@gmail.com');
  const [password, setPassword] = useState('Test-123');
  const [rememberUser, setRememberUser] = useState(false);
  // Remaining states
  const [errors, setErrors] = useState({});
  const [pendingAuthToken, setPendingAuthToken] = useState('');
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  // const [captchaResponse, setCaptchaResponsed] = useRecaptcha();

  /**
   * A useEffect hook that runs when the component mounts and when the user changes.
   * It checks if there is a logged user, if so, it navigates to the events page.
   *
   * @return {void}
   */
  useEffect(() => {
    if (auth.user) {
      navigate('/events');
    }
  }, [auth.user]);

  /**
   * A useEffect hook that runs when the component mounts.
   * It checks if there is a logged user, if so, it navigates to the events page.
   * It retrieves the email of a previously logged in user from local storage and sets the email state.
   *
   * @return {void}
   */
  useEffect(() => {
    const prevUser = storageUtils.getItem('email');
    if (prevUser) {
      setEmail(prevUser);
    }
  }, []);

  /**
   * Resets the form state.
   *
   * @return {void} This function does not return anything.
   */
  const resetFormState = () => {
    setErrors({});
    setEmail('');
    setPassword('');
    setPendingAuthToken('');
    setEmailVerificationCode('');
  };

  /**
   * A function that handles the successful login response.
   *
   * @param {Object} email - The email of the logged in user.
   * @param {Object} response - The response object.
   * @return {void}
   */
  const handleLoginSuccess = (email, response) => {
    console.log('handleLoginSuccess');
    let loggedInUser = response.data;

    console.log(loggedInUser);
    // store the user email in localStorage if the user wants to be remembered
    if (rememberUser) {
      storageUtils.setItem('email', email);
    }
    // Set the expiration date in localStorage

    storageUtils.setItem('expirationDate', loggedInUser.sessionExpirationDate);
    // Delete the session expiration date from the loggedInUser object
    delete loggedInUser.sessionExpirationDate;
    // Set user on the auth context
    auth.formatAndSetUser(loggedInUser);

    resetFormState();

    setTimeout(() => {
      navigate('/events');
    }, 100);
  };

  /**
   * A function that handles login errors.
   *
   * @param {Error} err - The error object.
   * @return {void}
   */
  const handleLoginError = (err) => {
    const errorMsg = 'Could not login. Verify your credentials and try again.';
    setErrors((prevErrors) => {
      return { ...prevErrors, auth: errorMsg };
    });
  };

  /**
   * A function that submits the form.
   *
   * @return {void} This function does not return anything.
   */
  const submitForm = (e) => {
    e.preventDefault();
    console.log('submitForm called');

    const errs = loginValidation(email, password);
    if (Object.keys(errs).length === 0) {
      const loginData = {
        email: email,
        password: password,
        rememberUser: rememberUser,
      };
      if (rememberUser) {
        storageUtils.setItem('email', email);
        storageUtils.setItem('rememberPatronUser', true);
      }
      login2(loginData)
        .then((res) => {
          console.log('login2 res: ', res);
          if (res.data.pending_authentication_token) {
            setPendingAuthToken(res.data.pending_authentication_token);
          } else {
            // TODO: Test to check if the response contains the user data, if not, inform user of failure
            console.log('response from login2 is: ', res.data);
            handleLoginSuccess(email, res);
          }
        })
        .catch((err) => {
          console.log('login2 err: ', err);

          handleLoginError(err);
        });
    } else {
      setErrors(errs);
    }
  };

  /**
   * Handles the successful authentication response.
   *
   * @param {Object} registrationData - The registration data object.
   * @param {Object} response - The response object containing user data.
   * @return {void} This function does not return anything.
   */
  const handleAuthenticationSuccess = (registrationData, response) => {
    console.log('response in handleAuthenticationSuccess', response);

    let loggedInUser = response.data;
    console.log('loggedInUser', loggedInUser);

    // store the user's email in localStorage if remember me is checked
    if (rememberUser) {
      storageUtils.setItem('email', registrationData.email);
    }

    // Set user on the auth context
    auth.formatAndSetUser(loggedInUser);
  };

  /**
   * Handles the form submission of verification code.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmitVerificationCode = (e) => {
    e.preventDefault();
    verifyemail(emailVerificationCode, pendingAuthToken)
      .then((res) => {
        const userData = {
          email: email,
          password: password,
        };
        resetFormState();
        handleAuthenticationSuccess(userData, res);
      })
      .catch((e) => {
        setEmailVerificationCode('');
      });
  };

  const clearErrorWhenTyping = () => {
    if (Object.keys(errors).length > 0) {
      setErrors({});
    }
  };

  return (
    <div className={styles.login}>
      {!pendingAuthToken ? (
        <div className={styles.loginCard}>
          <AuthenticationHead isLogin={true} />
          <form onSubmit={submitForm}>
            <FormInput
              ariaLabel={'username'}
              controlId={'loginEmail'}
              label="Username"
              type="email"
              placeholder="Enter your email address"
              value={email}
              onInputChange={setEmail}
              error={errors.email}
              onClearError={clearErrorWhenTyping}
            />
            <FormInput
              ariaLabel={'password'}
              controlId={'loginPassword'}
              label={'Password'}
              type={'password'}
              placeholder={'Password'}
              value={password}
              onInputChange={setPassword}
              minLength={8}
              error={errors.password || errors.auth}
              onClearError={clearErrorWhenTyping}
            />
            <KeepMeSignAndForgot
              onChangeChecked={setRememberUser}
              rememberUser={rememberUser}
            />
            <p className={styles.legal}>
              {' '}
              By continuing past this page, you agree to the Terms of Use and
              understand that information will be used as described in our
              Privacy Policy.
            </p>
            <CustomButton type={'submit'} title={'Log In'} />
          </form>
          <AuthenticationFoot />
        </div>
      ) : (
        <ConfirmVerificationCode
          handleSubmitVerificationCode={handleSubmitVerificationCode}
          emailVerificationCode={emailVerificationCode}
          setEmailVerificationCode={setEmailVerificationCode}
          clearErrorWhenTyping={clearErrorWhenTyping}
          errors={errors}
        />
      )}
    </div>
  );
}

import styles from './TicketSection.module.scss';
import { formatNumberToCurrency } from '../../utils/CommonUtils';

export default function TicketSection({
  allowedTickets,
  section,
  showingLanguage,
  ticketsSelected,
  onValueChange,
  capacity,
}) {
  console.log('allowedTickets are => ', allowedTickets);
  const availableTickets =
    allowedTickets <= capacity ? allowedTickets : capacity;

  // const maxNumberOfTickets = availableTickets - numberOfTicketsSelected;
  // console.log('maxNumberOfTickets are => ', maxNumberOfTickets);

  const types = section.types;

  return (
    <section className={styles.ticketSection}>
      <div className={styles.ticketSectionContainer}>
        <div className={styles.titleLine}>
          <span className={styles.sectionName}>
            {section.name[showingLanguage]}
          </span>
          <span>Quantity</span>
        </div>
        {types.map((type) => (
          <div className={styles.ticketLine} key={type.id}>
            <p className={styles.ticketName}>
              <span>{type.name[showingLanguage]}</span>
              <span>{formatNumberToCurrency(type.price)}</span>
            </p>
            <span className={styles.ticketQuantity}>
              {ticketsSelected && (
                <input
                  type="number"
                  max={availableTickets}
                  min={0}
                  value={ticketsSelected[type.id]?.selectedQuantity || 0}
                  onChange={onValueChange}
                  name={type.id}
                />
              )}
            </span>
          </div>
        ))}
      </div>
    </section>
  );
}

export const getNumberOfTicketsPerPerformance = (
  ticketItems,
  performanceId
) => {
  if (!ticketItems) return 0;
  const cartItemsOfTheSamePerformance = ticketItems.filter(
    (item) => item.performanceId === performanceId
  );
  return cartItemsOfTheSamePerformance.reduce(
    (acc, item) => acc + item.selectedQuantity,
    0
  );
};

import { useCallback, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { checkoutHandler } from '../../utils/APIUtils';

export default function CheckoutForm({ checkoutObj }) {
  console.log('loadStripe in CheckoutForm =>', loadStripe);
  console.log(EmbeddedCheckoutProvider, EmbeddedCheckout);

  console.log(
    'stripeAccount in CheckoutForm =>',
    checkoutObj.promoter.stripeId
  );

  const stripePromise = useMemo(
    () =>
      loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
        stripeAccount: checkoutObj.promoter.stripeId,
      }),
    []
  );

  console.log('stripePromise in CheckoutForm =>', stripePromise);

  const fetchClientSecret = useCallback(
    // create a checkout session
    async () => {
      try {
        const clientSecret = await checkoutHandler(checkoutObj);
        console.log('Resolved client secret:', clientSecret);
        if (!clientSecret || typeof clientSecret !== 'string') {
          throw new Error('Invalid client secret');
        }
        return clientSecret;
      } catch (error) {
        console.error('fetchClientSecret error:', error);
        throw error;
      }
    },
    [checkoutObj]
  );

  console.log('fetchClientSecret in CheckoutForm =>', fetchClientSecret);

  const options = {
    fetchClientSecret,
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}

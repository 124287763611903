import styles from './Timer.module.scss';
import { useState, useEffect, useRef } from 'react';
import { useCart } from '../../context/useCart';

export default function Timer({ variant }) {
  // Get the cart expiration date from context
  const { cartExpiration, getCartData, cart } = useCart();
  const timerRef = useRef(null);

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = cartExpiration - now;

    if (difference > 0) {
      return {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return null; // Timer has expired
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    clearInterval(timerRef.current);
    if (cartExpiration) {
      timerRef.current = setInterval(() => {
        const updatedTimeLeft = calculateTimeLeft();
        if (updatedTimeLeft <= 0) {
          console.log('Cart expired - callclearing interval');
          clearInterval(timerRef.current);
          getCartData();
          return;
        }
        if (updatedTimeLeft <= 60) {
          // TODO: CREATE A MODAL TO SHOW THAT THE USER'S CART IS ABOUT TO EXPIRED
        }
        setTimeLeft(updatedTimeLeft);

        if (!updatedTimeLeft) {
          clearInterval(timerRef.current);
        }
      }, 1000);
    } else {
      clearInterval(timerRef.current);
      setTimeLeft(null);
    }
    return () => clearInterval(timerRef.current);
  }, [cart, cartExpiration]);

  if (!timeLeft || !cart || timeLeft <= 0) {
    return <div></div>;
  }

  const formatSeconds = (seconds) => String(seconds).padStart(2, '0');
  return (
    <div className={`${styles.timer} ${styles[variant]}`}>
      <p>
        <span>Time left:</span> {timeLeft.minutes}:
        {formatSeconds(timeLeft.seconds)}
      </p>
    </div>
  );
}

import { useEffect } from 'react';
import { useCart } from '../../context/useCart';
import { Link } from 'react-router-dom';
import styles from './CheckoutRedirect.module.scss';
import CustomButton from '../../components/CustomButton/CustomButton';
import { deleteCart } from '../../utils/APIUtils';

export default function CheckoutRedirect() {
  const { setCart } = useCart();

  // TODO: set cart to empty and delete cart from local storage

  useEffect(() => {
    const handleRedirect = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      console.log('urlParams in CheckoutRedirect =>', urlParams);
      const sessionId = urlParams.get('session_id');
      console.log('sessionId in CheckoutRedirect =>', sessionId);
      await deleteCart();
    };
    handleRedirect();
  }, []);

  return (
    <div className={styles.checkoutRedirect}>
      <div className={styles.checkoutRedirectContainer}>
        <h1>Your order is completed</h1>
        <h2>Order #1231231231231</h2>
        <p>You will receive an email confirmation with your order details.</p>
        <div className={styles.btnsContainer}>
          <CustomButton title="Share with friends" />
          <CustomButton title="Add to my calendar" />
          <Link>Open this order in my profile</Link>
        </div>
      </div>
    </div>
  );
}

import styles from './TypeCard.module.scss';
import { typeCartCardDataTransformer } from './CartDataUtils';
import { useCart } from '../../context/useCart';
import { useEffect, useState } from 'react';
export default function TypeCard({ typeData, showingLanguage }) {
  const transformedData = typeCartCardDataTransformer(typeData);
  const { selectedQuantity, typeName, sectionName, subTotal, typeId } =
    transformedData;
  const [quantity, setQuantity] = useState(selectedQuantity);
  const { handleEditCartItems } = useCart();

  // TODO: ADD FRONT END CHECK ON THE QUANTITY SELECTION
  // TODO: ADD CHANGING QUANTITY WORKFLOW

  useEffect(() => {
    setQuantity(selectedQuantity);
  }, [selectedQuantity]);

  useEffect(() => {
    setQuantity(selectedQuantity);
  }, [selectedQuantity]);

  return (
    <div className={styles.typeCard}>
      {/* ticket image here */}
      <label>
        Qty:
        <input
          type="number"
          value={quantity}
          min={0}
          name={typeId}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </label>
      <button onClick={() => handleEditCartItems(typeId, quantity)}>
        update
      </button>
      <p>
        <strong>{typeName[showingLanguage]}</strong> Ticket of{' '}
        <strong>{sectionName[showingLanguage]}</strong> Section
      </p>
      <span className={styles.subTotal}>{subTotal}</span>
      {/* delete button here */}
    </div>
  );
}

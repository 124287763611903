import React, { useState, useEffect, createContext, useContext } from 'react';
import { addToCart } from '../utils/APIUtils';
import { setItem, getItem } from '../utils/LocalStorageUtils';
import { clearCartLocalStorage } from '../utils/CommonUtils';
import {
  getCartDataByCartId,
  removeItemsFromCart,
  addPatronIdToCart,
  getCartDataByPatronId,
} from '../utils/APIUtils';
import { useAuth } from '../context/useAuth';
import { set } from 'lodash';

export const CartContext = createContext();
export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState({});
  const { user } = useAuth();
  const cartId = getItem('cartId') || null;
  const cartExpiration = getItem('cartExpiration');

  const clearCart = () => {
    clearCartLocalStorage();
    setCart({});
  };

  // Set the cart with the fetched data
  // If the cart is empty or adding a new item newData will be false (do not need to be passed)
  // if the cart is not empty and it is set a new cart data, newData will be true
  const setCartWithFetchedData = (data, newData) => {
    console.log(
      'data and newData in setCartWithFetchedData is => ',
      data,
      newData
    );

    setCart((prevItems) => {
      // Clone the previous cartItems
      const updatedItems = newData ? {} : { ...prevItems };
      // Ensure ticketItems is an array
      updatedItems.ticketItems = updatedItems.ticketItems || [];
      updatedItems.patronUserId = data.patronUserId;
      // Loop throught the added items response
      if (data.ticketItems.length === 0) {
        console.log('No items to add to cart');
        return;
      }
      data.ticketItems.forEach((item) => {
        // Check if there is already an entry for the typeId
        const existingIndex = updatedItems.ticketItems.findIndex(
          (uItem) => item.typeId === uItem.typeId
        );
        // If there is no existing entry, add a new one
        if (existingIndex === -1) {
          updatedItems.ticketItems.push(item);
        } else {
          // If there is an existing entry, update its ticketItems
          // Add the added quantity to the existing tickets
          updatedItems.ticketItems[existingIndex].selectedQuantity +=
            item.selectedQuantity;
        }
      });
      return updatedItems;
    });
  };

  const getCartData = async () => {
    const cartData = await getCartDataByCartId();
    if (
      !cartData ||
      cartData.message === 'CART_EXPIRED' ||
      cartData.message === 'CART_NOT_FOUND'
    ) {
      clearCart();
      return;
    }
    console.log('*****  cartData is => ', cartData);
    return cartData;
  };

  const getInitialCartData = async () => {
    if (Object.keys(cart).length > 0) return;
    const cartData = await getCartData();
    console.log('cartData is => ', cartData);
    if (!cartData) return;
    // Set new cartExpiration in local storage
    setItem('cartExpiration', new Date(cartData.expiredAt).getTime());
    setCartWithFetchedData(cartData);
  };

  // Fetch cart data on component mount
  useEffect(() => {
    getInitialCartData();
  }, []);

  useEffect(() => {
    const handleUserChange = async () => {
      if (Object.keys(cart).length > 0 && user && user.patronUserId && cartId) {
        // TODO: Check if there is a cart and if the cart has a patronUserId
        const addedPatronIdToCartResponse = await addPatronIdToCart(
          user.patronUserId,
          cartId
        );
        console.log(
          'addedPatronIdToCartResponse is => ',
          addedPatronIdToCartResponse
        );
      } else {
        if (Object.keys(cart).length === 0 && user && user.patronUserId) {
          // Get cart data using patronUserId
          console.log('user.patronUserId is => ', user.patronUserId);

          const cart = await getCartDataByPatronId(user.patronUserId);
          console.log('cart IN HANDLE USER CHANGE IS => ', cart);

          if (
            cart.message === 'CART_NOT_FOUND' ||
            cart.message === 'CART_EXPIRED'
          ) {
            console.log('cart.message is => ', cart.message);

            return;
          }

          setCartWithFetchedData(cart, true);
        }
      }
    };
    handleUserChange();
  }, [user]);

  useEffect(() => {
    console.log(
      'useEffect called to add patronId to cart. To add patronId to cart if not in the cart yet.'
    );

    const addPatronIdToCartResponse = async () => {
      if (!cart) return;
      if (
        Object.keys(cart).length > 0 &&
        !cart.patronUserId &&
        user &&
        user.patronUserId
      ) {
        console.log(
          'useEffect called to add patronId to cart. To add patronId to cart'
        );

        const response = await addPatronIdToCart(user.patronUserId, cartId);
        setCart((prevCart) => {
          return {
            ...prevCart,
            patronUserId: response.patronUserId,
          };
        });
      }
    };
    addPatronIdToCartResponse();
  }, [cart, user]);

  //use reduce to calculate the number of tickets
  const getTicketCount = (cart) => {
    let ticketCount = 0;
    // cartItems.forEach((cartItem) => {
    console.log('cart in getTicketCount is => ', cart);

    if (!cart || Object.keys(cart).length === 0) return 0;
    const typeCount = cart.ticketItems.reduce((tickets, ticket) => {
      if (ticket) {
        return tickets + ticket.selectedQuantity;
      }
      return tickets;
    }, 0);
    console.log('typeCount is => ', typeCount);

    ticketCount += typeCount;
    // });
    return ticketCount;
  };
  const cartCount = getTicketCount(cart);

  console.log('cartCount is => ', cartCount);
  console.log('cart is => ', cart);

  // Handles the add to cart action
  const handleAddToCart = async (items) => {
    console.log('handleAddToCart called to add items => ', items);
    if (items.length === 0) return;
    const requestData = {
      items: items,
    };
    // Check if there is a cartId and add it to the request if so
    // TODO: Check if the user is logged in, if so, add the PatronId to the request
    if (cartId) {
      requestData.cartId = cartId;
    }

    const addToCartResponse = await addToCart(requestData);
    console.log('addToCartResponse is => ', addToCartResponse);

    // Check and update the cart data
    // Add the cart data to local storage
    if (addToCartResponse.cartId && addToCartResponse.expiredAt) {
      setItem('cartId', addToCartResponse.cartId);
      setItem(
        'cartExpiration',
        new Date(addToCartResponse.expiredAt).getTime()
      );
    } else {
      // TODO: HANDLE ERROR
    }
    setCartWithFetchedData(addToCartResponse);
  };

  // Handles editing cart items quantity from the cart page
  const handleEditCartItems = async (typeId, newQuantity) => {
    console.log(
      'handleEditCartItems called to edit cart for typeId => ',
      typeId
    );
    // Compare the previous quantity with the new quantity
    const previousQty = cart.ticketItems.find(
      (item) => item.typeId === typeId
    ).selectedQuantity;
    const difference = newQuantity - previousQty;
    if (difference === 0) return;
    // Get the object from the cart that has the same typeId
    const typeItem = cart.ticketItems.find((item) => item.typeId === typeId);
    if (previousQty > newQuantity) {
      // Get an array with the items to be removed (array of ticketIds)
      const removedItems = typeItem.ticketsIds.splice(0, Math.abs(difference));
      const requestData = {
        cartId: cartId,
        removedItems: removedItems,
      };
      // Make the API call to remove the items
      try {
        await removeItemsFromCart(requestData);
      } catch (error) {
        console.log('error on removeItemsFromCart: ', error);
      }
      // Get the updated cart data
      const cartData = await getCartData();
      // Update the cart state
      setCartWithFetchedData(cartData, true);
    }

    if (previousQty < newQuantity) {
      console.log(typeItem);
      const itemWithNewQuantity = {
        performanceId: typeItem.performanceId,
        sectionId: typeItem.sectionId,
        typeId: typeItem.typeId,
        selectedQuantity: difference,
      };
      console.log('itemWithNewQuantity is => ', itemWithNewQuantity);

      await handleAddToCart([itemWithNewQuantity]);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        handleAddToCart,
        cartCount,
        cartExpiration,
        getCartData,
        handleEditCartItems,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

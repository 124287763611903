import { parse } from 'qs';
import { formatNumberToCurrency } from '../../utils/CommonUtils';

const getEventlinxUnitFee = (eventlinxFee, price) => {
  // Calculate the percentage fee for the Eventlinx fee
  const eventlinxPercentFee =
    parseInt(eventlinxFee.percent * price * 100) / 100;

  // If the percentage fee is less than the minimum fee, use the minimum fee
  let unitEventlinxFee =
    eventlinxPercentFee <= eventlinxFee.minFee
      ? eventlinxFee.minFee
      : eventlinxPercentFee;

  // If the percentage fee is greater than the maximum fee, use the maximum fee
  unitEventlinxFee =
    unitEventlinxFee > eventlinxFee.maxFee
      ? eventlinxFee.maxFee
      : unitEventlinxFee;

  return unitEventlinxFee;
};

const getItemFeesAmount = (item) => {
  const fees = item.fees;
  const price = item.price;
  const { eventlinxFee, promoterFee, affiliateFee } = fees;
  const priceIncludes = item.event.priceIncludes;
  const unitEventlinxFee = getEventlinxUnitFee(eventlinxFee, price);

  // Calculate the total fee amount per item
  let itemFeeAmount = 0;
  // If the price does not include platform fee, add the eventlinx fee to item fee amount
  if (!priceIncludes.platformFee) {
    itemFeeAmount +=
      parseInt(unitEventlinxFee * item.selectedQuantity * 100) / 100;
  }
  // If the price does not include promoter fee, add the promoter fee to item fee amount
  if (!priceIncludes.promoterFee) {
    itemFeeAmount += parseInt(promoterFee * item.selectedQuantity * 100) / 100;
  }
  // If the price does not include affiliate fee, add the affiliate fee to item fee amount
  if (!priceIncludes.affiliateFee) {
    itemFeeAmount += parseInt(affiliateFee * item.selectedQuantity * 100) / 100;
  }

  return itemFeeAmount;
  // return feesAmount;
};
// Calculates the event total to be shown in the cart
// The fees are calculated based on what is included in the price, the eventlinx fee is included in the fees amount
// The application fee is always passed to the checkout session
export const getEventTotal = (eventItems) => {
  const flatedPromoterItems = Object.values(eventItems).flat();

  if (flatedPromoterItems.length > 0) {
    const total = flatedPromoterItems.reduce(
      (acc, item) => {
        console.log('item in reduce in getEventTotal is => ', item);
        // const priceIncludes = item.event.priceIncludes;
        const fees = item.fees;
        const price = item.price;
        const { eventlinxFee, affiliateFee } = fees;

        const unitEventlinxFee = getEventlinxUnitFee(eventlinxFee, price);

        console.log(
          'unitEventlinxFee after testing max fee is => ',
          unitEventlinxFee
        );

        // // Calculate the total fee amount per item
        const itemFeeAmount = getItemFeesAmount(item);

        acc.ticketsAmount +=
          parseInt(item.price * item.selectedQuantity * 100) / 100;
        acc.feesAmount += itemFeeAmount;
        // application fee is always the sum of the eventlinx fee and the affiliate fee
        acc.applicationFee +=
          parseInt(unitEventlinxFee * item.selectedQuantity * 100) / 100 +
          parseInt(affiliateFee * item.selectedQuantity * 100) / 100;
        return acc;
      },
      { feesAmount: 0, ticketsAmount: 0, applicationFee: 0 }
    );
    console.log('event total is => ', total);

    return total;
  }
  return 0;
};

export const getEventsTotal = (groupsByEvents) => {
  const promotersTotal = {};
  Object.keys(groupsByEvents).forEach((eventId) => {
    promotersTotal[eventId] = getEventTotal(groupsByEvents[eventId]);
  });

  return promotersTotal;
};

export const groupByPerformances = (groupsByPromoters) => {
  const newGroupsByPromoters = {};
  Object.keys(groupsByPromoters).forEach((promoterId) => {
    const promoterItems = groupsByPromoters[promoterId];
    const groupedItems = {};
    promoterItems.forEach((item) => {
      const performanceId = item.performanceId;
      groupedItems[performanceId] = groupedItems[performanceId] || [];
      groupedItems[performanceId].push(item);
    });
    newGroupsByPromoters[promoterId] = groupedItems;
  });
  return newGroupsByPromoters;
};

// Receive the ticketItems and group them by events to show in the cart page
// It is necessary for the checkout process
// Since the checkout process is based on events
export const groupByEvents = (ticketItems) => {
  console.log('ticketItems are in groupByEvents is => ', ticketItems);
  const eventsGroupedPerformances = {};
  ticketItems.forEach((item) => {
    const eventId = item.event.eventId;
    eventsGroupedPerformances[eventId] =
      eventsGroupedPerformances[eventId] || [];
    eventsGroupedPerformances[eventId].push(item);
  });
  console.log('grouped by events object is => ', eventsGroupedPerformances);

  return eventsGroupedPerformances;
};

export const getEventNames = (eventsGroupedByEvents) => {
  console.log(
    'eventsGroupedByEvents in getEventNames is => ',
    eventsGroupedByEvents
  );

  const eventsNames = {};
  Object.keys(eventsGroupedByEvents).forEach((eventId) => {
    const items = eventsGroupedByEvents[eventId];
    console.log('items in getEventNames is => ', items);

    if (!eventsNames[eventId]) {
      eventsNames[eventId] = items[0].event.eventName;
    }
  });
  return eventsNames;
};

// Transform the data to be used in the CartCard
export const cartCardDataTransformer = (item) => {
  console.log('item in cartCardDataTransformer is => ', item);

  const data = {
    performanceId: item[0].performanceId,
    performanceName: item[0].performanceName,
    eventId: item[0].event.eventId,
    eventName: item[0].event.eventName,
    date: item[0].startTime,
    address:
      item[0].location.addressLine1 +
      item[0].location.addressLine2 +
      ', ' +
      item[0].location.city +
      ', ' +
      item[0].location.province +
      ', ' +
      item[0].location.country +
      ', ' +
      item[0].location.postalCode,
  };
  return data;
};

// Get the sum of the tickets amount to be used in the CartCard
const getTicketsAmount = (performanceTicketData) => {
  const amount = performanceTicketData.reduce((acc, type) => {
    acc += type.price * type.selectedQuantity;
    return acc;
  }, 0);
  return parseInt(amount * 100) / 100;
};

// Get the sum of the fees amount to be used in the CartCard
const getFeesAmount = (performanceTicketData) => {
  const feesAmounts = performanceTicketData.map((item) => {
    return getItemFeesAmount(item);
  });
  console.log('feesAmounts in getFeesAmount are => ', feesAmounts);

  const feesAmount = feesAmounts.reduce((acc, feeAmount) => {
    acc += feeAmount;
    return acc;
  }, 0);
  return parseInt(feesAmount * 100) / 100;
};

// Get the subtotal, fees amount and tickets amount to be used in the CartCard
export const getAmounts = (performanceTicketData) => {
  console.log(
    'performanceTicketData in getAmounts is => ',
    performanceTicketData
  );

  const ticketAmount = getTicketsAmount(performanceTicketData);
  const feesAmount = getFeesAmount(performanceTicketData);
  const subTotal = ticketAmount + feesAmount;

  return {
    subTotal: subTotal,
    ticketsAmount: ticketAmount,
    feesAmount: feesAmount,
  };
};

export const typeCartCardDataTransformer = (typeData) => {
  const data = {
    selectedQuantity: typeData.selectedQuantity,
    typeName: typeData.typeName,
    sectionName: typeData.sectionName,
    subTotal: formatNumberToCurrency(
      parseInt(typeData.price * typeData.selectedQuantity * 100) / 100
    ),
    typeId: typeData.typeId,
  };
  return data;
};

// Get the fees that show up in the cart depending on the promoter total
// and what is included in the price

export const getCheckoutItemsArray = (eventItems, showingLanguage) => {
  const checkoutItems = [];
  eventItems.forEach((item) => {
    const checkoutItem = {
      name: item.performanceName[showingLanguage],
      taxCode: item.taxCode,
      price: item.price,
      quantity: item.selectedQuantity,
    };
    checkoutItems.push(checkoutItem);
  });
  return checkoutItems;
};

export const getPromoterCheckoutObject = (eventItems) => {
  const promoterObj = {
    id: eventItems[0].promoter.promoterId,
    stripeId: eventItems[0].promoter.promoterStripeId,
  };
  return promoterObj;
};

// Assembles the checkout object to be sent to the API call to checkout
export const assembleCheckoutObject = (
  eventItems,
  showingLanguage,
  cartId,
  patronObj
) => {
  const eventTotal = getEventTotal(eventItems, showingLanguage);
  const applicationFee = eventTotal.applicationFee;
  const checkoutItems = getCheckoutItemsArray(eventItems, showingLanguage);
  const promoterObject = getPromoterCheckoutObject(eventItems);
  const priceIncludesTax = eventItems[0].event.priceIncludes.tax;
  const taxBehavior = priceIncludesTax ? 'inclusive' : 'exclusive';

  // Add a item line if we have feeAmount greater than 0
  if (eventTotal.feesAmount > 0) {
    checkoutItems.push({
      name: 'Fees',
      taxCode: 'txcd_20030000', // TODO: See a way to retrieve this from the API (It is hardcoded for now)
      price: eventTotal.feesAmount,
      quantity: 1,
    });
  }
  // TODO: ADD CHECKS TO THESE VALUES

  const checkoutObject = {
    cartId: cartId,
    items: checkoutItems,
    applicationFee: applicationFee,
    promoter: promoterObject,
    patron: patronObj,
    taxBehavior: taxBehavior,
  };
  return checkoutObject;
};
